import React, { useState } from 'react';
import { Button, TextField, Container, Typography, Box, Tabs, Tab } from '@mui/material';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { googleProvider, twitterProvider } from '../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import TwitterIcon from '@mui/icons-material/Twitter';
import GoogleLogo from '@mui/icons-material/Google';
import MailIcon from '@mui/icons-material/Email';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
};

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isNewUser, setIsNewUser] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const auth = getAuth();

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const handleLogin = async () => {
    try {
      if (isNewUser) {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        if (!user.emailVerified) {
          await user.sendEmailVerification();
          console.log('Verification email sent.');
        }
      } else {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        if (!userCredential.user.emailVerified) {
          console.error('Email not verified');
          return;
        }
      }
      onLogin();
    } catch (error) {
      console.error('Error during authentication:', error);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      onLogin();
    } catch (error) {
      console.error('Error during Google authentication:', error);
    }
  };

  const handleTwitterLogin = async () => {
    try {
      await signInWithPopup(auth, twitterProvider);
      onLogin();
    } catch (error) {
      console.error('Error during Twitter authentication:', error);
    }
  };

  const handleForgotPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      console.log('Password reset email sent.');
    } catch (error) {
      console.error('Error sending password reset email:', error);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box mt={5} textAlign="center">
        <Typography variant="h4" gutterBottom>Login</Typography>
        <Tabs value={tabIndex} onChange={handleTabChange} centered>
        <Tab
    label={
      <Box display="flex" alignItems="center" justifyContent="center">
        <GoogleLogo style={{ marginRight: 5 }} />
        <TwitterIcon style={{ marginLeft: 5 }} />        
      </Box>
    }
  /> 
          <Tab
    label={
      <Box display="flex" alignItems="center" justifyContent="center">
        <MailIcon style={{ marginRight: 5 }} />
    
      </Box>
    }
  />
</Tabs>
        <TabPanel value={tabIndex} index={0}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleGoogleLogin}
            startIcon={<GoogleLogo style={{ fontSize: 20 }} />}
            fullWidth
            style={{ marginBottom: '10px' }}
          >
            Sign in with Google
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleTwitterLogin}
            startIcon={<TwitterIcon />}
            fullWidth
          >
            Sign in with Twitter
          </Button>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button variant="contained" color="primary" onClick={handleLogin} fullWidth>
            {isNewUser ? 'Sign Up' : 'Login'}
          </Button>
          <Button variant="text" onClick={() => setIsNewUser(!isNewUser)} style={{ marginTop: '10px' }}>
            {isNewUser ? 'Already have an account? Login' : 'Don\'t have an account? Sign Up'}
          </Button>
          <Button variant="text" onClick={handleForgotPassword} style={{ marginTop: '10px' }}>
            Forgot Password?
          </Button>
        </TabPanel>
      </Box>
    </Container>
  );
};

export default Login;
