import React, { useState, useEffect, useImperativeHandle, forwardRef, useCallback } from 'react';
import { db } from '../firebase';
import { collection, query, where, orderBy, limit, startAfter, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { Box, Typography, Card, CardContent, CardActions, IconButton, Button, CircularProgress, Grid, CardHeader, Tooltip, Popover, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { Mood as MoodIcon, Share as ShareIcon, Delete as DeleteIcon, Twitter as TwitterIcon, WhatsApp as WhatsAppIcon, Link as LinkIcon , Description as DescriptionIcon} from '@mui/icons-material';
import { format } from 'date-fns';

const TranscriptionList = forwardRef(({ userId }, ref) => {
    const [transcriptions, setTranscriptions] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [loadingMore, setLoadingMore] = useState(false);
    const [anchorElMap, setAnchorElMap] = useState({});
    const [confirmDeleteId, setConfirmDeleteId] = useState(null);
    const [selectedTranscription, setSelectedTranscription] = useState(null);

    const fetchTranscriptions = useCallback(async () => {
        try {
            const q = query(
                collection(db, "transcriptions"),
                where("userId", "==", userId),
                orderBy("timestamp", "desc"),
                limit(6)
            );
            const querySnapshot = await getDocs(q);
            const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
            setLastVisible(lastVisible);
            // const newTranscriptions = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            const newTranscriptions = querySnapshot.docs.map(processTranscription);
            setTranscriptions(newTranscriptions);
        } catch (error) {
            console.error("Error fetching transcriptions: ", error);
        }
        

    }, [userId]);

    const handleTranscriptionClick = (transcription) => {
        setSelectedTranscription({...transcription, showEmotionsOnly: false});
      }
      
      const handleEmotionClick = (transcription) => {
        setSelectedTranscription({...transcription, showEmotionsOnly: true});
      }

      const handleCloseDialog = () => {
        setSelectedTranscription(null);
      };

      const getEmotionColor = (emotion) => {
        if (!emotion) return '#757575'; // Default color for undefined emotions
        
        const colors = {
          happiness: '#4CAF50',
          sadness: '#2196F3',
          anxiety: '#FFC107',
          gratitude: '#9C27B0',
          anger: '#F44336',
          fear: '#FF5722',
          hope: '#8BC34A',
          confidence: '#00BCD4',
          neutral: '#9E9E9E' // Adding a color for neutral
        };
        return colors[emotion.toLowerCase()] || '#757575';
      };

    useEffect(() => {
        fetchTranscriptions();
    }, [fetchTranscriptions]);

    useImperativeHandle(ref, () => ({
        refreshTranscriptions: () => fetchTranscriptions()
    }));

    const loadMoreTranscriptions = async () => {
        if (!lastVisible) return;
        setLoadingMore(true);
        try {
            const q = query(
                collection(db, "transcriptions"),
                where("userId", "==", userId),
                orderBy("timestamp", "desc"),
                startAfter(lastVisible),
                limit(6)
            );
            const querySnapshot = await getDocs(q);
            const newLastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
            setLastVisible(newLastVisible);
            // const newTranscriptions = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            const newTranscriptions = querySnapshot.docs.map(processTranscription);
            setTranscriptions(prevTranscriptions => [...prevTranscriptions, ...newTranscriptions]);
        } catch (error) {
            console.error("Error fetching more transcriptions: ", error);
        }
        setLoadingMore(false);
    };

    const processTranscription = (doc) => ({
        id: doc.id,
        ...doc.data(),
        dominantEmotion: doc.data().dominantEmotion || null,
        emotions: doc.data().emotions || {},
        selfReflection: doc.data().selfReflection || '',
        transcription: doc.data().transcription || ''
      });

    const deleteTranscription = async (id) => {
        try {
            await deleteDoc(doc(db, "transcriptions", id));
            setTranscriptions(transcriptions.filter(transcription => transcription.id !== id));
            console.log("Document deleted with ID: ", id);
        } catch (error) {
            console.error("Error deleting document: ", error);
        }
    };

    const generateShareLink = async (id) => {
        try {
            const docRef = doc(db, "transcriptions", id);
            await updateDoc(docRef, { public: true });
            const shareLink = `https://app.noted.cx/transcript?id=${id}`;
            return shareLink;
        } catch (error) {
            console.error("Error generating share link: ", error);
        }
    };

    const copyToClipboard = async (id) => {
        try {
            const link = await generateShareLink(id);
            navigator.clipboard.writeText(link);
           // alert("Link copied to clipboard!");
        } catch (error) {
            console.error("Error copying link to clipboard: ", error);
        }
    };

    const handleShareClick = (event, id) => {
        // Close the delete confirmation tooltip when sharing is triggered
        setConfirmDeleteId(null);
        setAnchorElMap((prev) => ({
            ...prev,
            [id]: event.currentTarget,
        }));
    };

    const handleClose = (id) => {
        setAnchorElMap((prev) => ({
            ...prev,
            [id]: null,
        }));
    };

    const handleDeleteClick = (id) => {
        // Close the sharing tooltip if delete confirmation is triggered
        setAnchorElMap({});
        
        if (confirmDeleteId === id) {
            deleteTranscription(id); // Delete if the user clicks delete again
            setConfirmDeleteId(null); // Reset confirmation state
        } else {
            setConfirmDeleteId(id); // Set confirmation state
        }
    };

    return (
        <Box mt={5}>
            <Typography variant="h6" gutterBottom>Previous Transcriptions</Typography>
            <Grid container spacing={2}>
                {transcriptions.map((transcription) => (
                    <Grid item xs={12} sm={6} md={4} lg={4} key={transcription.id}>
                        <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardHeader
                                titleTypographyProps={{ variant: 'h6', noWrap: true }}
                                subheaderTypographyProps={{ variant: 'body2', noWrap: true }}                                
                                title={format(new Date(transcription.timestamp.toDate()), 'd-MMM, K:mm aaa')}
                                sx={{
                                    '.MuiCardHeader-content': {
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }
                                }}
                            />
<CardContent>
      <Typography variant="body1">{transcription.selfReflection}</Typography>
    </CardContent>
                            
                            <CardActions>
                            <IconButton onClick={() => handleTranscriptionClick(transcription)}>
  <DescriptionIcon />
</IconButton>
<IconButton onClick={() => handleEmotionClick(transcription)}>

  <MoodIcon style={{ color: getEmotionColor(transcription.dominantEmotion) }} />
</IconButton>
      <Box flexGrow={1} /> {/* This will push the following icons to the right */}
                                <IconButton color="primary" onClick={(e) => handleShareClick(e, transcription.id)}>
                                    <ShareIcon />
                                </IconButton>
                                <Popover
                                    id={`share-popover-${transcription.id}`}
                                    open={Boolean(anchorElMap[transcription.id])}
                                    anchorEl={anchorElMap[transcription.id]}
                                    onClose={() => handleClose(transcription.id)}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Box p={1} display="flex" alignItems="center">
                                        <Tooltip title="Copy Link">
                                            <IconButton color="primary" onClick={() => { copyToClipboard(transcription.id); handleClose(transcription.id); }}>
                                                <LinkIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Share on Twitter">
                                            <IconButton color="primary" onClick={async () => {
                                                const link = await generateShareLink(transcription.id);
                                                window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent('Check out this transcription! ' + link)}`, '_blank');
                                                handleClose(transcription.id);
                                            }}>
                                                <TwitterIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Share on WhatsApp">
                                            <IconButton color="primary" onClick={async () => {
                                                const link = await generateShareLink(transcription.id);
                                                window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent('Check out this transcription: ' + link)}`, '_blank');
                                                handleClose(transcription.id);
                                            }}>
                                                <WhatsAppIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Popover>
                                <Tooltip title={confirmDeleteId === transcription.id ? "Click again to confirm" : "Delete"}>
                                    <IconButton 
                                        color={confirmDeleteId === transcription.id ? "error" : "primary"} 
                                        onClick={() => handleDeleteClick(transcription.id)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            {loadingMore && <CircularProgress />}
            {lastVisible && (
                <Button variant="contained" color="primary" onClick={loadMoreTranscriptions} style={{ marginTop: '10px' }}>
                    Load More
                </Button>
            )}
<Dialog open={!!selectedTranscription} onClose={handleCloseDialog}>
  <DialogTitle>
    {selectedTranscription?.showEmotionsOnly ? 'Emotion Analysis' : 'Transcription Details'}
  </DialogTitle>
  <DialogContent>
    {selectedTranscription && (
      <>
        {selectedTranscription.showEmotionsOnly ? (
          <Box>
            
            {selectedTranscription.emotions && Object.entries(selectedTranscription.emotions).map(([emotion, score]) => (
              <Typography key={emotion} style={{ color: getEmotionColor(emotion) }}>
                {emotion}: {score}
              </Typography>
            ))}
          </Box>
        ) : (
          <Typography variant="body1">{selectedTranscription.transcription}</Typography>
        )}
      </>
    )}
  </DialogContent>
</Dialog>


        </Box>
    );
});

export default TranscriptionList;
