import React, { useEffect, useRef } from 'react';

const ThoughtNodesVisualizer = ({ analyser }) => {
    const canvasRef = useRef(null);
    const nodes = useRef([]);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        let animationFrameId;

        const colors = [
            'rgba(255, 165, 0, 1)',  // Soft orange
            'rgba(173, 216, 230, 1)', // Light blue
            'rgba(144, 238, 144, 1)', // Light green
            'rgba(238, 130, 238, 1)', // Light violet
            'rgba(255, 182, 193, 1)'  // Light pink
        ];

        const createNode = (x, y, amplitude) => ({
            x,
            y,
            radius: 5,
            maxRadius: amplitude * 8, // Slightly reduced max radius
            alpha: 1,
            speed: 0.3, // Slower speed for a more calming effect
            color: colors[Math.floor(Math.random() * colors.length)]
        });

        const drawConnections = () => {
            nodes.current.forEach((node1, i) => {
                for (let j = i + 1; j < nodes.current.length; j++) {
                    const node2 = nodes.current[j];
                    const distance = Math.hypot(node2.x - node1.x, node2.y - node1.y);

                    if (distance < 120) { // Increased distance for connection to reduce clutter
                        ctx.strokeStyle = `rgba(255, 165, 0, ${Math.min(node1.alpha, node2.alpha)})`; // Soft orange for connections
                        ctx.lineWidth = 1;
                        ctx.beginPath();
                        ctx.moveTo(node1.x, node1.y);
                        ctx.lineTo(node2.x, node2.y);
                        ctx.stroke();
                    }
                }
            });
        };

        const drawNodes = () => {
            nodes.current.forEach((node, index) => {
                node.radius += node.speed;
                node.alpha -= 0.01;

                if (node.radius > node.maxRadius || node.alpha <= 0) {
                    nodes.current.splice(index, 1);
                    return;
                }

                ctx.beginPath();
                ctx.arc(node.x, node.y, node.radius, 0, Math.PI * 2, false);
                // Apply the node's color with fading alpha
                ctx.fillStyle = node.color.replace(', 1)', `, ${node.alpha})`);
                ctx.fill();
            });
        };

        const draw = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            drawConnections();
            drawNodes();

            if (analyser) {
                const bufferLength = analyser.fftSize;
                const dataArray = new Uint8Array(bufferLength);
                analyser.getByteTimeDomainData(dataArray);

                const maxValue = Math.max(...dataArray);
                if (maxValue > 128 + 20) {  // Increased threshold to reduce node creation frequency
                    const x = Math.random() * canvas.width; // Random position on the canvas
                    const y = Math.random() * canvas.height;
                    nodes.current.push(createNode(x, y, maxValue / 128));
                }
            }

            animationFrameId = requestAnimationFrame(draw);
        };

        draw();

        return () => {
            cancelAnimationFrame(animationFrameId);
        };
    }, [analyser]);

    return (
        <canvas
            ref={canvasRef}
            width="auto"
            height="200"
            style={{ display: 'block', margin: '10px auto', backgroundColor: 'transparent' }}
        />
    );
};

export default ThoughtNodesVisualizer;
