import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Container, Typography, Card, CardContent, CardActions, CardHeader, Avatar, IconButton, CircularProgress } from '@mui/material';
import { Share as ShareIcon, ThumbUp as ThumbUpIcon } from '@mui/icons-material';
import { format } from 'date-fns';
import { red } from '@mui/material/colors';

const TranscriptPage = () => {
    const [transcript, setTranscript] = useState(null);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const transcriptId = params.get('id');

    useEffect(() => {
        const fetchTranscript = async () => {
            if (transcriptId) {
                const docRef = doc(db, 'transcriptions', transcriptId);
                const docSnap = await getDoc(docRef);
    
                if (docSnap.exists() && docSnap.data().public) {
                    console.log("Transcript data:", docSnap.data()); // Add this line
                    setTranscript(docSnap.data());
                }
            }
            setLoading(false);
        };
    
        fetchTranscript();
    }, [transcriptId]);
    

    

    if (loading) {
        return (
            <Container maxWidth="sm">
                <Typography variant="h4" gutterBottom>Loading...</Typography>
                <CircularProgress />
            </Container>
        );
    }

    if (!transcript) {
        return (
            <Container maxWidth="sm">
                <Typography variant="h4" gutterBottom>Transcript not found or not public.</Typography>
            </Container>
        );
    }

    return (
        <Container maxWidth="sm" sx={{ mt: 5 }}>
            <Card>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: red[500] }}>
                            {transcript.userName ? transcript.userName[0] : 'U'}
                        </Avatar>
                    }
                    title={transcript.userName || "Unknown User"}
                    subheader={format(new Date(transcript.timestamp.toDate()), 'PPpp')}
                />
                <CardContent>
                    <Typography variant="body1" color="textSecondary">
                        {transcript.transcription}
                    </Typography>
                </CardContent>
                {/* <CardActions disableSpacing>
                    <IconButton aria-label="like">
                        <ThumbUpIcon />
                    </IconButton>
                    <IconButton aria-label="share">
                        <ShareIcon />
                    </IconButton>
                </CardActions>
                <CardContent>
                    <Typography variant="caption" color="textSecondary">
                        Shared by: {transcript.userName || "Unknown User"}
                    </Typography>
                </CardContent> */}
            </Card>
        </Container>
    );
};

export default TranscriptPage;
