import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF7043', // Orange color
      contrastText: '#FFFFFF', // White text
    },
    secondary: {
      main: '#FFFFFF', // White color
      contrastText: '#FF7043', // Orange text
    },
    background: {
      default: '#FFFFFF', // White background
      paper: '#F5F5F5', // Light grey for cards, etc.
    },
    text: {
      primary: '#333333', // Dark grey text for better contrast on white
      secondary: '#FF7043', // Orange text for secondary items
    },
  },
  typography: {
    h1: {
      color: '#FF7043',
    },
    h2: {
      color: '#FF7043',
    },
    h3: {
      color: '#FF7043',
    },
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px', // Optional: Rounded corners
        },
      },
    },
  },
});

export default theme;
