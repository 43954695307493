import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'; // Import your custom theme
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import AudioRecorder from './components/AudioRecorder.js';
import Login from './components/Login';
import Stripelink from './components/Stripelink.js'; // Import your Payment component
import { auth, db } from './firebase';
import { doc, getDoc } from 'firebase/firestore';
import { collection, query, where, getDocs } from 'firebase/firestore'; // Add these imports
import { AppBar, Toolbar, Button, Container, CircularProgress, Box, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import TranscriptPage from './components/TranscriptPage';
import logo from './components/logo_noted_white.png';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useLocation, BrowserRouter as Router, Route, Navigate } from 'react-router-dom'; // Replace `Redirect` with `Navigate`

const App = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null); // State for menu anchor
    const location = useLocation();
    const [hasPaid, setHasPaid] = useState(false);
    const [checkingPayment, setCheckingPayment] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const checkPaymentStatus = async () => {
            if (user) {
                try {
                    const usersRef = collection(db, 'users');
                    const q = query(usersRef, where('clientReferenceId', '==', user.uid));
                    const querySnapshot = await getDocs(q);

                    if (!querySnapshot.empty) {
                        const userDoc = querySnapshot.docs[0];
                        setHasPaid(userDoc.data().paymentStatus === 'paid');
                        console.log("User has Paid - checked and passed");
                    } else {
                        setHasPaid(false);
                        console.log("User has not paid yet");
                    }
                } catch (error) {
                    console.error('Error checking payment status:', error);
                }
            }
        };

        if (user) {
            checkPaymentStatus();
        }
    }, [user]);

    const handleLogout = () => {
        signOut(auth);
        handleClose();
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isTranscriptPage = location.pathname === '/transcript';

    if (loading) {
        return (
            <Container maxWidth="sm">
                <Typography variant="h4" gutterBottom>Loading...</Typography>
                <CircularProgress />
            </Container>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <div>
                <AppBar position="static">
                    <Toolbar>
                        <Box style={{ flexGrow: 1 }}>
                            <img src={logo} alt="Noted Logo" style={{ height: '40px' }} />
                        </Box>
                        {user ? (
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" style={{ marginRight: '10px' }}>
                                    Hi, {user.displayName}
                                </Typography>
                                {!hasPaid && (
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ marginRight: '10px' }}
                  onClick={() => {
                    const stripeLink = `https://buy.stripe.com/fZeaF31eK5KhcHCbII?prefilled_email=${encodeURIComponent(user.email)}&client_reference_id=${encodeURIComponent(user.uid)}`;
                    window.location.href = stripeLink;
                  }}
                >
                  Upgrade
                </Button>
              )}
                                <IconButton
                                    edge="end"
                                    color="inherit"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                >
                                    <AccountCircleIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                </Menu>
                            </Box>
                        ) : !isTranscriptPage && (
                            <Button color="inherit" href="/login">Sign In</Button>
                        )}
                    </Toolbar>
                </AppBar>
                {isTranscriptPage ? (
                    <TranscriptPage />
                ) : user ? (
                        <AudioRecorder userId={user.uid} userName={user.displayName} onTranscription={(text) => console.log(text)}  hasPaid={hasPaid} userEmnail = {user.email} />
                    ) : (
                    <Login onLogin={() => setUser(auth.currentUser)} />
                )}
            </div>
        </ThemeProvider>
    );
};

export default App;
